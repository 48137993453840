.ant-form {
  color: $form-label-color;
}

.ant-form-item-label > label {
  color: $form-label-color;
  letter-spacing: 0.6px;
  &::after {
    display: none;
  }
}

.ant-form-item-has-error.ant-form-item-has-feedback
  .ant-form-item-children-icon,
.ant-form-item-has-error .ant-form-item-explain,
.ant-form-item-has-error .ant-form-item-split {
  color: $error-color;
  letter-spacing: 0.4px;
}

.ant-form-item-has-success.ant-form-item-has-feedback
  .ant-form-item-children-icon {
  color: $form-label-color;
}

.ant-form-item-has-error .ant-input-number,
.ant-form-item-has-error .ant-picker,
.ant-form-item-has-error .ant-input,
.ant-form-item-has-error .ant-select-selector {
  border-color: $error-color !important;
}

.ant-form-item-label > label:after {
  content: ':';
  margin: 0 8px 0 2px;
  position: relative;
  top: -0.5px;
}

.ant-input-group.ant-input-group-compact > * {
  display: block;
}
